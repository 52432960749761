"use client";

import {
  DisclaimerDialog,
  ProbabilityOfSuccessMeter,
} from "#app/(secured)/planning/my-plan/_shared/ProbabilityOfSuccessMeter";
import {
  useQueryOptionsFinancialPlansStatus,
  useQuerySuccessMeter,
} from "#app/_api/external-financial-plan-service";
import { ErrorCard } from "#app/_ui/components/cards/ErrorCard";
import { PendingCard } from "#app/_ui/components/cards/PendingCard";
import {
  Card,
  CardContent,
  CardFooter,
  CardMedia,
  type CardRootProps,
} from "#ui/card";
import { LinkButton } from "#ui/link";
import { H2, P } from "#ui/typography";
import { useQuery } from "@tanstack/react-query";
import Image from "next/image";

function CardPlanMeter(props: CardRootProps) {
  const { isPending, isError, data } = useQuerySuccessMeter();

  if (isPending) {
    return <PendingCard {...props} />;
  }

  if (isError) {
    return <ErrorCard {...props} />;
  }

  return (
    <Card {...props}>
      <CardContent className="space-y-17px text-center">
        <H2 size="h6" className="flex items-center justify-center gap-3px">
          <span>Plan Probability of Success</span> <DisclaimerDialog />
        </H2>
        <ProbabilityOfSuccessMeter {...data} />
      </CardContent>
      <CardFooter palette="white">
        <LinkButton href="/planning/my-plan" className="w-full">
          See my plan
        </LinkButton>
      </CardFooter>
    </Card>
  );
}

function CardPlanUpdate(props: CardRootProps) {
  return (
    <Card {...props}>
      <CardMedia
        palette="primary"
        pattern="stripe"
        className="rounded-t-lg md:rounded-t-xl"
      >
        <Image
          src="/Images/marketing.png"
          alt="A laptop with the My Plan page open"
          width="256"
          height="152"
          className="w-full max-w-220px"
          priority
        />
      </CardMedia>
      <CardContent className="space-y-20px">
        <div className="space-y-7px">
          <H2 size="h6">Have your finances changed?</H2>
          <P size="sm" palette="neutral-subtle">
            Let your advisor know if your financial situation has changed.
          </P>
        </div>
        <div>
          <LinkButton href="/planning" variant="outline" className="w-full">
            Submit a change
          </LinkButton>
        </div>
      </CardContent>
    </Card>
  );
}

function CardPlanStart(props: CardRootProps) {
  return (
    <Card {...props}>
      <CardMedia
        palette="secondary"
        pattern="stripe"
        className="rounded-t-lg md:rounded-t-xl"
      >
        <Image
          src="/Images/marketing.png"
          alt="A laptop with the My Plan page open"
          width="256"
          height="152"
          className="w-full max-w-220px"
          priority
        />
      </CardMedia>
      <CardContent className="space-y-20px">
        <div className="space-y-7px">
          <H2 size="h6">
            Planning for your future. Let your advisor know if your finances
            have changed.
          </H2>
          <P size="sm" palette="neutral-subtle">
            Your Baird Financial Advisor can help you prioritize and plan so you
            can achieve your goals of tomorrow.
          </P>
        </div>
        <div>
          <LinkButton href="/planning" className="w-full">
            Get Started
          </LinkButton>
        </div>
      </CardContent>
    </Card>
  );
}

export function CardPlanning(props: CardRootProps) {
  const { data, isPending, isError } = useQuery(
    useQueryOptionsFinancialPlansStatus(),
  );

  if (isPending) {
    return <PendingCard {...props} />;
  }

  if (isError) {
    return <ErrorCard {...props} />;
  }

  if (data.canUseFinancialPlanning && data.hasVisiblePlans) {
    return <CardPlanMeter {...props} />;
  }

  return (
    <>
      <CardPlanUpdate {...props} />
      <CardPlanStart {...props} />
    </>
  );
}

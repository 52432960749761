"use client";

import { getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";
import { Segment } from "#app/(unauthorized)/authentication/segment";
import { useJwtAccounts } from "#app/(unauthorized)/authentication/useJwt";
import { allQueryKeys } from "#app/_api/allQueryKeys";
import { useQueryFinancialPlansDocumentsAll } from "#app/_api/external-financial-plan-service";
import type { DocumentMetaData } from "#app/_api/shared-document-model-types";
import { AccountInfo } from "#app/_ui/components/accounts/AccountInfo";
import { ErrorCardContent } from "#app/_ui/components/cards/ErrorCard";
import { NoResultsCardContent } from "#app/_ui/components/cards/NoResultsCard";
import { PendingCardContent } from "#app/_ui/components/cards/PendingCard";
import {
  PdfViewerDialog,
  PdfViewerDialogTrigger,
} from "#app/_ui/components/media/PdfViewerDialog";
import { getBlob } from "#app/lib/fetchClient";
import { logError } from "#app/lib/logger";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  type CardRootProps,
} from "#ui/card";
import { Icon } from "#ui/icon";
import { type IconName } from "#ui/icon.names";
import { LinkButton } from "#ui/link";
import { cx } from "#ui/style.utils";
import { H2, Span } from "#ui/typography";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";

// Using `Exclude` until other types are added
type DocumentSourceType = Exclude<
  DocumentMetaData[number]["type"],
  "SecureDocExchange" | "Confirm" | "Check" | "Tax"
>;

type DocumentMetaDataType = {
  meta: DocumentMetaData[number];
  type: DocumentSourceType;
  docType: DocumentType;
};

// Items commented out until `Exclude` changes
const documentTypeMap = {
  // Check: {
  //   iconName: "baird-check",
  //   bgColor: "bg-[#3B909D]",
  //   text: "Check",
  // },
  ClientCommunication: {
    bgColor: "bg-[#AB5232]",
    iconName: "baird-client-communication",
    text: "Client Communication",
  },
  // Confirm: {
  //   bgColor: "bg-[#4A7F6C]",
  //   iconName: "baird-confirmation",
  //   text: "Confirmation",
  // },
  FinPlan: {
    bgColor: "bg-primary-100",
    iconName: "baird-financial-plan",
    text: "Financial Plan",
  },
  // SecureDocExchange: {
  //   bgColor: "baird-primary-100",
  //   iconName: "baird-secure-document",
  //   text: "From my advisor",
  // },
  Statement: {
    bgColor: "bg-[#1A4B82]",
    iconName: "baird-marketing-strategy",
    text: "Statement",
  },
  // Tax: {
  //   bgColor: "bg-black",
  //   iconName: "baird-secure-document",
  //   text: "Tax Document",
  // },
} as const satisfies Record<
  DocumentSourceType,
  { bgColor: string; iconName: IconName; text: string }
>;

const MAX_ACCOUNTS_LENGTH = 17;

export function CardRecentDocuments(props: CardRootProps) {
  const accounts = useJwtAccounts();
  const isPreclient = getCurrentJwt().segment === Segment.Preclient;

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate() + 1);

  const yearAgo = new Date();
  yearAgo.setHours(0, 0, 0, 0);
  yearAgo.setFullYear(yearAgo.getFullYear() - 1);

  const params = {
    accounts: accounts,
    beginDate: yearAgo,
    endDate: today,
  };

  const resultsClientCommunication = useQuery({
    ...allQueryKeys.externalClientsCommunicationsService.clientCommunicationsDocuments(
      {
        beginDate: params.beginDate.toISOString(),
        endDate: params.endDate.toISOString(),
        acctIndex: getCurrentJwt().getAccountIndex(params.accounts),
      },
    ),
    placeholderData: keepPreviousData,
    enabled: !isPreclient,
  });

  const resultsFinPlan = useQueryFinancialPlansDocumentsAll({
    beginDate: params.beginDate.toISOString(),
    endDate: params.endDate.toISOString(),
  });

  const resultsStatement = useQuery({
    ...allQueryKeys.externalStatementsService.statementsDocuments({
      acctIndex: getCurrentJwt().getAccountIndex(params.accounts),
      beginDate: params.beginDate.toISOString(),
      endDate: params.endDate.toISOString(),
    }),
    placeholderData: keepPreviousData,
    enabled: !!params.accounts && !isPreclient,
  });

  const results = [
    resultsClientCommunication,
    resultsFinPlan,
    resultsStatement,
  ];

  const isError = results.some((el) => el.isError === true);
  const isPending = results.some((el) => el.isLoading === true);
  const isSuccess = results.every((el) => el.isSuccess === true);

  let error: Error | null = null;
  if (isError) {
    error = results.find((el) => el.error != null) as unknown as Error;
    logError(error);
  }

  const allDocs = [
    ...(resultsClientCommunication.data?.map((el) => {
      return {
        meta: el,
        type: "ClientCommunication",
        docType: el.type,
        accountNumber: el.accountNumber,
      } as unknown as DocumentMetaDataType;
    }) ?? []),
    ...(resultsFinPlan.data?.map((el) => {
      return {
        meta: el,
        type: "FinPlan",
        docType: el.type,
        accountNumber: el.accountNumber,
      } as unknown as DocumentMetaDataType;
    }) ?? []),
    ...(resultsStatement.data?.map((el) => {
      return {
        meta: el,
        type: "Statement",
        docType: el.type,
        accountNumber: el.accountNumber,
      } as unknown as DocumentMetaDataType;
    }) ?? []),
  ]
    .flatMap((el) =>
      el.meta.docs.map(
        (docInfo) =>
          ({
            doc: docInfo,
            date: parseISO(el.meta.date),
            sourceType: el.type,
            docType: el.docType,
            accountNumber: el.meta.accountNumber,
          }) as {
            sourceType: DocumentSourceType;
            date: Date;
            doc: DocumentMetaData[number]["docs"][number];
            docType: DocumentType;
            accountNumber: string;
          },
      ),
    )
    .sort((x, y) => (x.date > y.date ? -1 : 1))
    .slice(0, 5);

  return accounts.length <= MAX_ACCOUNTS_LENGTH ? (
    <div>
      <Card {...props}>
        <CardHeader palette="gray">
          <H2 size="h6" className="flex items-center gap-10px">
            <Icon name="baird-secure-document" size="baird-md" />
            <span>Recent Documents</span>
          </H2>
        </CardHeader>
        {isPending ? <PendingCardContent /> : null}
        {isError ? <ErrorCardContent /> : null}
        {isSuccess && allDocs.length > 0 ? (
          <CardContent className="p-0">
            <ul className="divide-y divide-shade-10">
              {allDocs.map((x) => (
                <li
                  key={x.doc.url}
                  className="relative isolate flex gap-15px px-30px py-20px"
                >
                  <div
                    className={cx(
                      "inline-block h-40px w-40px rounded-md bg-primary-100 p-9px",
                      documentTypeMap[x.sourceType].bgColor,
                    )}
                  >
                    <Icon
                      name={documentTypeMap[x.sourceType].iconName}
                      palette="white"
                      size="baird-sm"
                    />
                  </div>
                  <div className="flex-1">
                    <Span className="block" size="sm">
                      {x.doc.desc}
                    </Span>
                    <Span className="block" size="sm" palette="neutral-subtle">
                      {documentTypeMap[x.sourceType].text}
                    </Span>
                    <Span
                      className="block"
                      palette="neutral-subtle"
                      size="sm"
                    >{`${format(x.date, "LLL d, yyyy")}`}</Span>
                    {x.accountNumber ? (
                      <AccountInfo
                        accountNumber={x.accountNumber}
                        className="block text-shade-70"
                      />
                    ) : null}
                  </div>
                  <div>
                    <PdfViewerDialog
                      fileName={x.doc.desc}
                      queryFn={() => getBlob(x.doc.url.slice(4))}
                      triggerElement={
                        <PdfViewerDialogTrigger className="before:absolute before:inset-0" />
                      }
                    />
                  </div>
                </li>
              ))}
            </ul>
          </CardContent>
        ) : !isPending && !isError ? (
          <NoResultsCardContent title="You have no recent documents" />
        ) : null}
        <CardFooter palette="white">
          <LinkButton className="w-full" href="/documents">
            See all
          </LinkButton>
        </CardFooter>
      </Card>
    </div>
  ) : null;
}

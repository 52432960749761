
import { z } from 'zod';

const envSchema = z.object({
    gatewayBaseUrl: z.string(),
    jwtTimerRefreshDelta: z.coerce.number().default(-1),
    autoLogOffIdleTime: z.coerce.number().default(-1),
    autoLogOffCountdownTime: z.coerce.number().default(30),
    dynatraceScript: z.string().optional(),
    recaptchaSiteKey: z.string(),
    yodleeFastLinkUrl: z.string(),
    yodleeIFrameUrl: z.string(),
    icIframeUrl: z.string(),
    icUrl: z.string(),
    wealthAccessUrl: z.string(),
    digitalOnboardingUrl: z.string(),
    websiteDomain: z.string(),
    outageBypassCode: z.string(),
});

export const env = envSchema.parse({
    // @ts-expect-error nextjs requires this syntax
    gatewayBaseUrl: process.env.NEXT_PUBLIC_GATEWAY_BASE_URL,
    // @ts-expect-error nextjs requires this syntax
    jwtTimerRefreshDelta: process.env.NEXT_PUBLIC_JWT_TIMER_REFRESH_DELTA,
    // @ts-expect-error nextjs requires this syntax
    autoLogOffIdleTime: process.env.NEXT_PUBLIC_AUTO_LOG_OFF_IDLE_TIME,
    // @ts-expect-error nextjs requires this syntax
    autoLogOffCountdownTime: process.env.NEXT_PUBLIC_AUTO_LOG_OFF_COUNTDOWN_TIME,
    // @ts-expect-error nextjs requires this syntax
    dynatraceScript: process.env.NEXT_PUBLIC_DYNATRACE_SCRIPT,
    // @ts-expect-error nextjs requires this syntax
    recaptchaSiteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
    // @ts-expect-error nextjs requires this syntax
    yodleeFastLinkUrl: process.env.NEXT_PUBLIC_YODLEE_FASTLINK_URL,
    // @ts-expect-error nextjs requires this syntax
    yodleeIFrameUrl: process.env.NEXT_PUBLIC_YODLEE_IFRAME_URL,
    // @ts-expect-error nextjs requires this syntax
    icIframeUrl: process.env.NEXT_PUBLIC_IC_IFRAME_URL,
    // @ts-expect-error nextjs requires this syntax
    icUrl: process.env.NEXT_PUBLIC_IC_URL,
    // @ts-expect-error nextjs requires this syntax
    wealthAccessUrl: process.env.NEXT_PUBLIC_WEALTH_ACCESS_URL,
    // @ts-expect-error nextjs requires this syntax
    digitalOnboardingUrl: process.env.NEXT_PUBLIC_DIGITAL_ONBOARDING_URL,
    // @ts-expect-error nextjs requires this syntax
    websiteDomain: process.env.NEXT_PUBLIC_WEBSITE_DOMAIN,
    // @ts-expect-error nextjs requires this syntax
    outageBypassCode: process.env.NEXT_PUBLIC_OUTAGE_BYPASS_CODE,
});


export default env;
import { getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";
import { Segment } from "#app/(unauthorized)/authentication/segment";
import type { GetQuotesData } from "#app/_api/external-market-research-service/get-quotes";
import { Anchor, type AnchorProps, type AnchorRef } from "#ui/link";
import { forwardRef } from "react";

export type AnchorSecuritySymbolRef = AnchorRef;
export interface AnchorSecuritySymbolProps extends AnchorProps {
  symbol: string;
  type: string;
}

export const urlMap = {
  FUN: "mutual-funds",
  ETF: "etfs",
  STO: "stocks",
} satisfies Record<string, string>;

const AnchorSecuritySymbol = forwardRef<
AnchorSecuritySymbolRef,
AnchorSecuritySymbolProps
>((props, ref) => {
  const jwt = getCurrentJwt();
  const { symbol, type } = props;
  const base = jwt.segment === Segment.NotLoggedIn ? "market-tools" : "investing-tools";
  const assetType = urlMap[type as keyof typeof urlMap];
  return <Anchor ref={ref} href={`/${base}/${assetType}/${symbol}-US`}>{symbol}</Anchor>;
});


AnchorSecuritySymbol.displayName = "AnchorSecuritySymbol";
export { AnchorSecuritySymbol };

// This file will be repurposed for shared content below after the AnchorSecuritySymbol component is replaced in CardRecentActivity.tsx


export function getLinkSecuritySymbolProps({
  symbol,
  symbolType,
}: {
  symbol?: string;
  symbolType?: string;
}):
  | { href: string; children: React.ReactNode }
  | { href?: never; children: React.ReactNode, "aria-hidden": boolean }
  | { href?: never; children?: never } {
  if (!symbol) {
    return {};
  }

  if (!symbolType || urlMap[symbolType as keyof typeof urlMap] === undefined) {
    return { children: symbol, "aria-hidden": false };
  }
  
  const jwt = getCurrentJwt();
  const base =
    jwt.segment === Segment.NotLoggedIn ? "market-tools" : "investing-tools";
  const assetType = urlMap[symbolType as keyof typeof urlMap];

  return {
    href: `/${base}/${assetType}/${symbol}-US`,
    children: symbol,
  };
}

export function selectSymbolTypeMap(data : GetQuotesData) {
  const quotes = new Map<string, string>();
  for (const item of data.data) {
    if (item.symbol) {
      quotes.set(item.symbol, item.securityType.securityTypeId);
    }
  }
  return quotes;
}
import { useCallback, useEffect, useSyncExternalStore } from "react";

function dispatchStorageEvent(key: string, newValue: string | null) {
  window.dispatchEvent(new StorageEvent("storage", { key, newValue }));
}

function removeSessionStorageItem(key: string) {
  window.sessionStorage.removeItem(key);
  dispatchStorageEvent(key, null);
}

function getSessionStorageItem(key: string) {
  return window.sessionStorage.getItem(key);
}

function setSessionStorageItem(key: string, value: string) {
  const stringifiedValue = JSON.stringify(value);
  window.sessionStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
}

function useSessionStorageSubscribe(callback: any) {
  window.addEventListener("storage", callback);
  return () => window.removeEventListener("storage", callback);
}

function getSessionStorageServerSnapshot() {
  throw Error("useSessionStorage is a client-only hook");
}

export function useSessionStorage(key: string, initialValue: any) {
  const getSnapshot = () => getSessionStorageItem(key);

  const store = useSyncExternalStore(
    useSessionStorageSubscribe,
    getSnapshot,
    // @ts-expect-error - sessionStorage is client-side only
    getSessionStorageServerSnapshot,
  );

  const setState = useCallback(
    (v: string | null | ((o: any) => string | null)) => {
      try {
        const nextState =
          typeof v === "function" ? v(store ? JSON.parse(store) : null) : v;

        if (nextState === undefined || nextState === null) {
          removeSessionStorageItem(key);
        } else {
          setSessionStorageItem(key, nextState);
        }
      } catch (e) {
        console.warn(e);
      }
    },
    [key, store],
  );

  useEffect(() => {
    if (
      getSessionStorageItem(key) === null &&
      typeof initialValue !== "undefined"
    ) {
      setSessionStorageItem(key, initialValue);
    }
  }, [key, initialValue]);

  return [store ? JSON.parse(store) : initialValue, setState];
}

export const SessionStorageKeys = {
  hasSeenModal: "hasSeenModal",
  outageBypassCode: "outageBypassCode"
} as const;

"use client";

import { getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";
import { Segment } from "#app/(unauthorized)/authentication/segment";
import { type GetMandatoryActivityData } from "#app/_api/external-bol-notification-service/get-mandatory-activity";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogCloseIconButton,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogMain,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
} from "#ui/alert-dialog";
import { Button } from "#ui/button";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useState } from "react";

export function AlertDialogOnboarding({ onClose }: { onClose: () => void }) {
  const [open, setOpen] = useState(true);

  const router = useRouter();

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent
          variant="center-scroll-inner"
          className="w-full max-w-350px"
        >
          <AlertDialogMain className="p-30px">
            <div className="grid gap-20px text-center">
              <div className="pb-10px">
                <Image
                  alt=""
                  src="/Images/group_1363.png"
                  width="706"
                  height="602"
                  className="mx-auto w-120px"
                  priority
                />
              </div>
              <div>
                <AlertDialogTitle className="font-semibold">
                  Continue opening your Baird accounts.
                </AlertDialogTitle>
              </div>
              <div>
                <AlertDialogDescription className="text-pretty font-normal text-shade-70">
                  We&rsquo;ve saved your progress towards opening your Baird
                  accounts. When you&rsquo;re ready to continue, click{" "}
                  <span className="whitespace-nowrap">
                    &ldquo;Let&rsquo;s Continue&rdquo;
                  </span>{" "}
                  below.
                </AlertDialogDescription>
              </div>
              <div className="grid gap-10px">
                <AlertDialogAction asChild>
                  <Button
                    onClick={() => {
                      onClose();
                      router.push("/account-services/open-account");
                    }}
                  >
                    Let&rsquo;s Continue
                  </Button>
                </AlertDialogAction>
                <AlertDialogCancel asChild>
                  <Button
                    variant="outline"
                    onClick={() => {
                      onClose();
                      setOpen(false);
                    }}
                  >
                    Remind me later
                  </Button>
                </AlertDialogCancel>
              </div>
            </div>
            <AlertDialogCloseIconButton
              onClick={() => {
                onClose();
                setOpen(false);
              }}
            />
          </AlertDialogMain>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}

export function toShowOnboardingDialogOrPage({
  mandatoryActivity,
}: {
  mandatoryActivity: GetMandatoryActivityData;
}) {
  const isPreclient = getCurrentJwt().segment === Segment.Preclient;
  const hasActivityId1 = mandatoryActivity.some((el) => el.activityID === 1);
  const hasActivityId3 = mandatoryActivity.some((el) => el.activityID === 3);
  const showPage = isPreclient && hasActivityId1 && !hasActivityId3;
  const showDialog = isPreclient && hasActivityId3;
  return { showPage, showDialog };
}
